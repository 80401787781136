import React from "react";
import "./styles.scss"

export default function Quotes() {
  return (
    <div className="quotes">
      <div className="item">
        <div className="quote">
        
        </div>
      </div>
      <div className="item">
        
      </div>
      <div className="item">
      
      </div>
      {/* <div className="item">
        <div className="quote">
          <p>
            The hardest choices require the strongest wills.<span>Thanos</span>
          </p>
        </div>
      </div>
      <div className="item">
        <div className="quote">
          <p>
            Genius, billionaire, playboy, philanthropist.<span>Tony Stark</span>
          </p>
        </div>
      </div>
      <div className="item">
        <div className="quote">
          <p>
            We never lose our demons. We only learn to live above them.
            <span>Ancient One</span>
          </p>
        </div>
      </div> */}
    </div>
  );
}
