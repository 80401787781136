import React from 'react'
import TopFooter from './components/topfooter'
import BotFooter from './components/botfooter'

export default function Footer() {
  return (
    <>
        <TopFooter/>
        <BotFooter/>
    </>
  )
}
