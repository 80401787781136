import React from "react";
import ListProduct from "../../components/listProduct";

export default function Product() {
  return (
    <>
      {/* <HeadingPage /> */}

      <ListProduct />
    </>
  );
}
